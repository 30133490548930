import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53')
];

export const server_loads = [0,4,6,8,9];

export const dictionary = {
		"/": [12],
		"/(app)/(language-specific)/de/shop/returns-service": [13,[2,3,4]],
		"/(app)/(language-specific)/de/shop/shipping-payment": [14,[2,3,4]],
		"/(app)/(language-specific)/en/shop/returns-service": [15,[2,5,6]],
		"/(app)/(language-specific)/en/shop/shipping-payment": [16,[2,5,6]],
		"/(app)/[language=language]/(website)": [41,[2,7,10]],
		"/(app)/[language=language]/(shop)/account": [~17,[2,7,8,9]],
		"/(app)/[language=language]/(shop)/account/orders": [18,[2,7,8,9]],
		"/(app)/[language=language]/(shop)/account/orders/[orderId]": [19,[2,7,8,9]],
		"/(app)/[language=language]/(shop)/account/password": [~20,[2,7,8,9]],
		"/(app)/[language=language]/(shop)/account/settings": [21,[2,7,8,9]],
		"/(app)/[language=language]/(website)/(legal)/imprint": [42,[2,7,10,11]],
		"/(app)/[language=language]/(shop)/login": [~22,[2,7,8]],
		"/(app)/[language=language]/(shop)/login/forgot-password": [~23,[2,7,8]],
		"/(app)/[language=language]/(shop)/login/reset-password": [~24,[2,7,8]],
		"/(app)/[language=language]/(website)/partner/brembo": [47,[2,7,10]],
		"/(app)/[language=language]/(website)/partner/ferodo": [48,[2,7,10]],
		"/(app)/[language=language]/(website)/partner/pagid": [49,[2,7,10]],
		"/(app)/[language=language]/(website)/partner/paoli": [50,[2,7,10]],
		"/(app)/[language=language]/(website)/partner/staeubli": [51,[2,7,10]],
		"/(app)/[language=language]/(website)/partner/totim": [52,[2,7,10]],
		"/(app)/[language=language]/(website)/partner/zf": [53,[2,7,10]],
		"/(app)/[language=language]/(website)/(legal)/privacy": [43,[2,7,10,11]],
		"/(app)/[language=language]/(shop)/register": [~25,[2,7,8]],
		"/(app)/[language=language]/(shop)/register/verification": [26,[2,7,8]],
		"/(app)/[language=language]/(shop)/registration/confirm": [27,[2,7,8]],
		"/(app)/[language=language]/(website)/(legal)/right-of-revocation": [44,[2,7,10,11]],
		"/(app)/[language=language]/(shop)/shop": [28,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/cart": [29,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/cart/error": [~30,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/category": [31,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/category/sprint-filter": [32,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/checkout": [~33,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/checkout/error": [~34,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/checkout/success": [~35,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/product": [36,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/product/b2b-list": [38,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/product/sf-p08f1/[[productVariantSlug=slug]]": [40,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/product/sf-p08/[[productVariantSlug=slug]]": [39,[2,7,8]],
		"/(app)/[language=language]/(shop)/shop/product/[productSlug=slug]/[[productVariantSlug=slug]]": [37,[2,7,8]],
		"/(app)/[language=language]/(website)/(legal)/terms-conditions-shop": [46,[2,7,10,11]],
		"/(app)/[language=language]/(website)/(legal)/terms-conditions": [45,[2,7,10,11]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';