import type { ParamMatcher } from '@sveltejs/kit';
import { supportedLocales } from '$lib/i18n/initI18n.js';

export const match: ParamMatcher = (param) => {
    for (const locale of supportedLocales) {
        if (param.startsWith(locale + '/')) {
            return false;
        }
    }

    return true;
};
