import { handleErrorWithSentry, replayIntegration } from '@sentry/sveltekit';
import { init } from '@jill64/sentry-sveltekit-cloudflare/client';

init('https://4172e154ba619d15e7e06e15583a9ad8@o4507141296685056.ingest.de.sentry.io/4507141298716752', {
    sentryOptions: {
        tracesSampleRate: 1.0,

        // This sets the sample rate to be 10%. You may want this to be 100% while
        // in development and sample at a lower rate in production
        replaysSessionSampleRate: 0.1,

        // If the entire session is not sampled, use the below sample rate to sample
        // sessions when an error occurs.
        replaysOnErrorSampleRate: 1.0,

        // If you don't want to use Session Replay, just remove the line below:
        integrations: [replayIntegration()]
    }
});

// If you have a custom error handler, pass it to `handleErrorWithSentry`
export const handleError = handleErrorWithSentry();
